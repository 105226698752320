import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';

class ProductHunt extends React.Component {
  componentDidMount() {
    if (document.location) {
      document.location.href = 'https://www.producthunt.com/posts/slab-5';
    }
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta name="referrer" content="origin" />
        </Helmet>
      </Layout>
    );
  }
}

export default ProductHunt;
